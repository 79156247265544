class AppProduct {
    constructor(id, attrObj) {
        this.id = id;

        // Copy all the properties from attrObj
        Object.assign(this, attrObj);
    }
}

export function firebaseToProduct(fbProduct) {
    // Create a custom standard product object to work with instead the SnapData returned from FB
    return new AppProduct(fbProduct.key, fbProduct.val());
}

export function slugify(str) {
    // Convert string into url ready text.
    return str.trim().replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, '-').toLowerCase();
}

export default {
    firebaseToProduct,
    slugify
}
