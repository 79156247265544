import React from 'react';
import { Route, Switch} from 'react-router-dom';
import Contacto from './Contacto';
import Nosotros from './Nosotros';
import Inicio from './Inicio';
import Producto from './Producto';


const AppRouter = (props) => {
    return(
        <Switch>
            <Route exact path='/' component={Inicio}/>
            <Route path='/contacto' component={Contacto}/>
            <Route path='/nosotros' component={Nosotros}/>
            {/* <Route path='/productos/:idProduct/:nameProduct' render={(props) => <Producto {...props}/>} /> */}
            <Route path='/productos/:idProduct/:nameProduct' component={Producto} />
        </Switch>
    )
}

export default AppRouter;
