import React from 'react';
import Buscador from './Buscador.js';
import ProductList from './ProductList';
import Notification from './Notification';
import logo from '../images/logo_transparent_letters.png';
import { firebaseToProduct } from './AppUtils';
const firebase = require("firebase/app");
require("firebase/database");


class Inicio extends React.Component {
  constructor(props) {
    super(props);
    this.db = null;
    this.state = {
      searchText: null,
      isLoading: false,
      results: []
    }
  }

  componentDidMount() {
    this.db = firebase.database();
  }

  fetchData(text) {
    this.setState({
      searchText: text,
      isLoading: true,
    })

    fetch('https://randomuser.me/api/?results=13&nat=us,dk,fr,gb')
      .then(response => response.json())
      .then(parsedJSON => parsedJSON.results.map(user => (
        {
          name: `${user.name.first} ${user.name.last}`,
          username: `${user.login.username}`,
          email: `${user.email}`,
          location: `${user.location.street}, ${user.location.city}`
        }
      )))
      .then(results => this.setState({
        results: results,
        isLoading: false
      }))
      .catch(error => console.log('parsing failed', error))
  }

  joinPaths = (text, paths, db) => {
    var returnCount = 0;
    var expectedCount = paths.length;
    var mergedObject = [];
    var that = this;
    var uniqueIds = new Set();

    paths.forEach(function (p) {
      that.db.ref('products').orderByChild(p).startAt(text).endAt(text + '\uf8ff').once('value',
        // success
        function (snap) {
          // add it to the merged data
          snap.forEach((r) => {
            if(!uniqueIds.has(r.key)){
              mergedObject.push(firebaseToProduct(r));
              uniqueIds.add(r.key);
            }
          });
          
          // when all paths have resolved, we invoke
          // the callback (jQuery.when would be handy here)
          if (++returnCount === expectedCount) {
            that.setState({
              isLoading: false,
              results: mergedObject
            });
          }
        },
        // error
        function (error) {
          returnCount = expectedCount + 1; // abort counters
        }
      );
    });
  }

  queryData(text) {
    this.setState({
      searchText: text,
      isLoading: true,
    })
    this.joinPaths(text, ['busqueda', 'busqueda_alt', 'categoria']);
  }

  normaliseText(str){
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  handleBuscadorChange = (event) => {
    let text = this.normaliseText(event.target.value);

    if (text !== 'undefined' && text !== '') {
      // Un-comment for Prod (actual search)
      this.queryData(text);

      // Uncomment for dev (avoid making search request)
      // this.setState({
      //   searchText: text,
      //   isLoading: false,
      //   results: test_data
      // });
    }
    else {
      this.setState({
        searchText: text,
        isLoading: false,
        results: []
      })
    }
  }

  handleSelectProduct = (prod) => {
    // Redirect to the new route
    // this.props.history.push('/path')    
    localStorage.setItem('selected-product', prod);
  }

  render() {
    const { isLoading, results } = this.state;
    return (
      <section>
        <div className="columns is-centered is-mobile">
          <div className="column is-narrow">
            <p className="image logo">
              <img src={logo} alt="Buscador de Productos" />
            </p >
          </div>
        </div>
        <div className="columns is-centered is-mobile">
          <div className="column is-full-mobile is-three-fifths-tablet">
            <div className="field">
              <div className={'control is-size-5-mobile is-size-4-tablet' + (isLoading ? ' is-loading' : '')}>
                <Buscador classNames="input is-size-5-mobile is-size-4-tablet"
                  holder="Escribe una marca o producto..." debounceTimeout={500}
                  onChange={this.handleBuscadorChange}>
                </Buscador>
              </div>
            </div>
            {results.length < 1 &&
              <Notification classNames="is-info">
                Prueba escribiendo <strong>&quot;cafe&quot;</strong> por ejemplo.
              </Notification>
            }
          </div>
        </div>
        {results.length > 0 &&
          // Don't render empty component when there are no results yet.
          <div className="section">
            <ProductList products={results}></ProductList>
          </div>
        }
        
        
      </section>
    )
  }
}

export default Inicio;

