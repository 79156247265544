import React from 'react';
import PageHeader from './PageHeader.js';
import PageFooter from './PageFooter';
var firebase = require("firebase/app");
require("firebase/database");
const Router = require('./AppRouter').default;


class App extends React.Component {

  constructor(props) {
    super(props);
    this.database_config = {
      databaseURL: "https://buscador-da678.firebaseio.com",
    }
  }

  componentWillMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(this.database_config);
      // console.log("Inicializando firebase app: " + firebase.app.name);
    }
  }

  // componentDidMount() {
  //   if (process.env.NODE_ENV !== 'production') {
  //     console.log('Looks like we are in development mode!');
  //   }
  // }


  render() {
    return (
      <div>
        <PageHeader></PageHeader>
        <Router></Router>
        <PageFooter author="https://github.com/javierb"></PageFooter>
      </div>
    );
  }
}

export default App;
