import React from 'react';
import { Redirect } from 'react-router-dom';
import no_photo from '../images/no_photo_lg.png';
import ProductList from './ProductList';
import { firebaseToProduct, slugify } from './AppUtils';

const firebase = require("firebase/app");
require("firebase/database");


class Producto extends React.Component {
    constructor(props) {
        super(props);
        this.idProd = props.match.params.idProduct;
        this.nameProd = props.match.params.nameProduct;

        this.state = {
            isLoading: props.location.state && props.location.state.selectedProduct ? false : true,
            isLoaded: false,
            redirect: false,
            product: props.location.state && props.location.state.selectedProduct ? props.location.state.selectedProduct : null,
            alternatives: []
        }
    }

    fetchAlternatives(product) {
        const category = product.categoria.trim()
        // console.log("fetching alternatives for ", category);
        firebase.database().ref('products')
            .orderByChild('categoria')
            .startAt(category)
            .endAt(category)
            .limitToLast(10)
            .once('value', snap => {
                var alts = [];
                snap.forEach((item) => {
                    // Don't include the same product.
                    if (item.key !== product.id)
                        alts.push(firebaseToProduct(item));
                });
                this.setState({ alternatives: alts });
            });
    }

    fetchProduct(idProduct, normProductName) {
        firebase.database().ref(`products/${idProduct}`)
            .once('value', (snap) => {
                let dbProd = firebaseToProduct(snap);
                let hasInvalidParams = false;
                // Check that the busqueda field matches the url, otherwise return 404 or redirect.
                if (slugify(dbProd.busqueda) !== normProductName) {
                    // console.log(`product ${idProduct} - ${normProductName} does not exist!`);
                    hasInvalidParams = true;
                    dbProd = null;
                }
                else {
                    // Now that we have the product we fetch the alternatives to it.
                    this.fetchAlternatives(dbProd);
                }

                this.setState({
                    product: dbProd,
                    redirect: hasInvalidParams,
                    isLoading: false
                });
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        let currentState = this.props.location.state;

        if(currentState && currentState.selectedProduct !== this.state.product){
            let productToRender = (currentState && currentState.selectedProduct) ? currentState.selectedProduct : null
            this.setState({
                isLoading: false,
                redirect: false,
                product: productToRender,
                alternatives: []
            })
            this.fetchAlternatives(productToRender);
        }
    }

    componentDidMount() {
        // fires immediately after the initial render.
        // Search for the product here so we will be forced to set initial state (product) - best practices.
        if (!this.state.product) {
            // This will occur when a user types the url directly or follows an external link so we don't have the product yet.
            this.fetchProduct(this.idProd, this.nameProd);
        }

        if (this.state.product && !this.state.alternatives.length > 0) {
            // This will happen when the user follows a link within this site so the product gets cached
            // and we already have it.
            this.fetchAlternatives(this.state.product);
        }
    }

    render() {
        if (this.state.redirect)
            return <Redirect to="/" />;

        const { isLoading, product, alternatives } = this.state;

        return (
            <section className="section">
                <div className="columns">
                    <div className="column box">
                        {isLoading &&
                            <div className="loader is-size-3-mobile is-size-1-tablet"></div>
                        }
                        {
                            !isLoading && product ? (() => {
                                const { nombre, provincia, ccaa, empresa, logo } = product;
                                return (
                                    <div className="section">
                                        <div className="columns is-vcentered">
                                            <div className="column is-narrow is-offset-5">
                                                <p className="has-text-centered">
                                                    <img className="image is-128x128 inline" src={logo ? logo : no_photo} alt="logo producto" />
                                                </p>
                                            </div>
                                            <div className="column has-text-centered is-narrow">
                                                <h1 className="title is-1">{nombre}</h1>
                                            </div>
                                        </div>

                                        <div className="columns is-multiline is-vcentered">
                                            <div className="column is-2 is-offset-4"><p className="has-text-right-tablet has-text-centered-mobile">Empresa Madre</p></div>
                                            <div className="column is-narrow media-content has-text-centered"><p className="subtitle">{empresa}</p></div>
                                            <div className="column is-2 is-offset-4"><p className="has-text-right-tablet has-text-centered-mobile">Provincia</p></div>
                                            <div className="column is-narrow media-content"><p className="subtitle has-text-centered-mobile">{provincia}</p></div>
                                            <div className="column is-2 is-offset-4"><p className="has-text-right-tablet has-text-centered-mobile">Comunidad Autónoma</p></div>
                                            <div className="column is-narrow media-content"><p className="subtitle has-text-centered-mobile">{ccaa}</p></div>
                                        </div>
                                    </div>
                                );
                            })() : null
                        }
                    </div>
                </div>
                <div className="row justify-content-center row-search">
                    <div className="col-md-8">
                        {
                            alternatives.length > 0 ? (() => {
                                return (<h1 className="subtitle">Productos similares</h1>);
                            })() : null
                        }
                        <ProductList products={this.state.alternatives}></ProductList>
                    </div>
                </div>
            </section>
        );
    }
}

export default Producto;