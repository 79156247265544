import React from 'react';

const Notification = (props) => {
    function handleClick(e) {
        let notification = e.target.parentNode;
        notification.parentNode.removeChild(notification);
    }
        
        
    const { classNames } = props;

    return (
        // We need the extra wrapping div element otherwise when closing the notification and searching we will get an exception.
        // https://reactjs.org/docs/integrating-with-other-libraries.html
        <div>
            <div className={'notification' + (classNames ? ' ' + classNames : '')}>
                <button className="delete" onClick={handleClick}></button>
                {props.children}
            </div>
        </div>
    );
}

export default React.memo(Notification);