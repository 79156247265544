import React from 'react';

const PageFooter = (props) => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="content has-text-centered">
                    <div className="columns is-mobile is-centered">
                        <p>Created with <span className="heart">&#9829;</span> in <a className="author" href="https://en.wikipedia.org/wiki/Alicante" target="noopener">Alicante</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default PageFooter;