import React from 'react';
import { Link } from 'react-router-dom';


const Nosotros = (props) => {
    return (
        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column box">
                        <h1 className="title">Quiénes somos</h1>
                        <p className="">
                            Somos dos amigos que a pesar de tener poco tiempo libre hemos conseguido recopilar
                            información sobre algunos de los productos más comúnes que podemos encontrar en
                            el mercado español. Nos intriga saber de dónde vienen, quién
                            y cómo se hacen, así que empezamos a crear una base de datos accesible desde un navegador
                            para que todo el mundo pueda consultarla. Esto lo hacemos por hobby y con la más pura intención
                            de informar. Iremos actualizando y añadiendo información más detallada regularmente en cuanto
                            nos sea posible.
                    </p>
                        <br />
                        <p>
                            Cuéntanos qué opinas o haznos una sugerencia <Link to="/contacto">aquí</Link>.
                    </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Nosotros;