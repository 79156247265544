import React from 'react';
import NavLink from 'react-router-dom/NavLink'
import logo from '../images/logo_nav.png';


const NavBurguer = (props) => {
	return (
		<div className={'navbar-burger burger' + (props.isActive ? ' is-active' : '')} data-target="topNav" onClick={props.onClick}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
}

const NavMenu = (props) => {
	return(
		<div className={'navbar-menu' + (props.isActive ? ' is-active' : '')}>
			{props.children}
		</div>
	);
}

class PageHeader extends React.Component {
	constructor(props){
		super(props);
		this.state = { activeBurger: false };
	}

	handleClick() {
    this.setState({
      activeBurger: !this.state.activeBurger
    })
  }

	render() {
		const activeBurgerMenu = this.state.activeBurger;

    return (
			<nav className="navbar is-white topNav" role="navigation" aria-label="main navigation">
				<div className="container">
					<div className="navbar-brand">
						<a className="navbar-item" href="../">
							<img src={logo} alt="Buscador de Productos" />
						</a>
						<NavBurguer isActive={activeBurgerMenu} onClick={() => this.handleClick()} />
					</div>
					<NavMenu isActive={activeBurgerMenu}>
						<div className="navbar-end">
							<NavLink exact to="/" className="navbar-item">Home</NavLink>
							<NavLink to="/nosotros" className="navbar-item">Quiénes Somos</NavLink>
							<NavLink to="/contacto" className="navbar-item">Contacto</NavLink>
						</div>
					</NavMenu>
				</div>
			</nav>
		);
	}
}

export default PageHeader;