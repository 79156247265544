import React from 'react';


const Contacto = (props) => {
    return (
        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column box">
                        <h1 className="title">Contacto</h1>
                        <p className="">
                            Cuentanos que opinas o haznos una sugerencia <a className="author" href="mailto:buscadorproductos@gmail.com">buscadorproductos@gmail.com</a>.
                    </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contacto;