import React from 'react';
import PropTypes from 'prop-types';
import ProductItem  from './ProductItem';


const ProducList = (props) => {

    if (!props.products.length > 0)
        return null;

    return (
        <div className="columns is-multiline">
            {
                props.products.map((product) => {
                    return (
                        <div className="column is-one-fifth" key={product.id}>
                            <ProductItem product={product} key={product.id}/>
                        </div>
                            )
                        })
                    }
        </div>
    )
}


ProducList.propTypes = {
    products: PropTypes.array.isRequired
}

// React.memo it’s similar to React.PureComponent but for function components instead of classes.
// They won't re-render if the props are equal. Implements it with a shallow prop comparison
export default React.memo(ProducList);
