import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import no_photo from '../images/no_photo_lg.png';
import { slugify } from './AppUtils.js';

// React.PureComponent implements shouldComponentUpdate() with a shallow prop and state comparison,
// And renderds the component only when either the props or state has changed.
class ProductItem extends React.PureComponent{
    
    render(){
        const { id, nombre, provincia, ccaa, logo, busqueda } = this.props.product;

        return(

        <div className="card card-product">
            <Link to={{ pathname: `/productos/${id}/${slugify(busqueda)}`, state: { selectedProduct: this.props.product } }}>
                <div className="card-content">
                    <div className="media">
                        <figure className="media-left">
                            <p className="image is-64x64">
                                <img className="card-logo" src={logo ? logo : no_photo} alt="logo producto" />
                            </p>
                        </figure>
                        <div className="media-content">
                            <p className="title is-5">{nombre}</p>
                        </div>
                    </div>

                    <div className="content has-text-centered">
                        <p className="subtitle">{provincia} - {ccaa}</p>
                        <p>+Info <br /> Alternativas</p>
                    </div>
                </div>
            </Link>
        </div>)
    }
}

ProductItem.propTypes = {
    product: PropTypes.object.isRequired
}

export default ProductItem;