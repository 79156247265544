import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';


class Buscador extends React.Component {

    constructor(props) {
        super(props);
        this.propagateChange = debounce(this.props.onChange, props.debounceTimeout);
        this.state = {
            loading: false,
            search_text: '',
            brands: []
        };
    }

    // Declaring with arrow function removes the need of binding the handler to "this" in the constructor.
    // to use babel-plugin-transform-class-properties
    handleChange = (e) => {
        e.persist();
        this.propagateChange(e);
    }

    render() {
        const { holder, classNames } = this.props;
        return (
            <input autoFocus title="buscar producto" placeholder={holder} className={classNames} type="search" onChange={this.handleChange} />
        )
    }
}

Buscador.propTypes = {
    holder: PropTypes.string,
    classNames: PropTypes.string,
    debounceTimeout: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Buscador;